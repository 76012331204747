import { createMemo, For, splitProps } from 'solid-js';
import type { JSX } from 'solid-js';

type Props = Omit<JSX.ImgHTMLAttributes<HTMLImageElement>, 'sizes'> & {
	formats?: Array<string>;
	sizes: Array<[number, number]>;
	crop?: 'entropy' | 'attention' | 'face';
};

export function Picture(props: Props) {
	const [internalProps, imgProps] = splitProps(props, ['formats', 'sizes', 'crop']);

	const sources = createMemo(() => {
		if (!imgProps.src) {
			return [];
		}

		return internalProps.sizes
			.sort((a, b) => b[0] - a[0])
			.map(([width, height], index, self) => {
				return (props.formats ?? ['avif', 'webp', 'jpeg']).map((format) => {
					const url = new URL(imgProps.src ?? '');
					url.searchParams.append('width', `${width}`);
					url.searchParams.append('height', `${height}`);
					url.searchParams.append('format', format);
					url.searchParams.append('mode', 'crop');
					url.searchParams.append('crop', internalProps.crop ?? 'entropy');
					return { url, format, minWidth: index === self.length - 1 ? 0 : Math.round(width * 0.85), width, height };
				});
			})
			.flat();
	});
	return (
		<>
			<picture class={imgProps.class}>
				<For each={sources()}>
					{(source) => (
						<source
							type={`image/${source.format}`}
							srcset={source.url.toString()}
							media={`(min-width: ${source.minWidth}px)`}
							width={source.width}
							height={source.height}
						/>
					)}
				</For>
				<img
					src={imgProps.src}
					alt={imgProps.alt ?? ''}
					{...imgProps}
					crossorigin="anonymous"
					fetchpriority={imgProps.loading === 'eager' ? 'high' : 'auto'}
				/>
			</picture>
		</>
	);
}
